import "./App.css";
import mainApi from "../../utils/api.js";
import CurrentUserContext from "../../contexts/CurrentUserContext.jsx";
import { useState, useEffect } from "react";
import {
  Navigate,
  useRoutes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import useVisible from "../../hooks/useVisible.jsx";

import Login from "../Login/Login.jsx";
import Register from "../Register/Register.jsx";
import InfoTooltip from "../InfoTooltip/InfoTooltip.jsx";
import SubscriptionCheck from "../SubscriptionCheck/SubscriptionCheck.jsx";
import DeviceCheck from "../DeviceCheck/DeviceCheck.jsx";
import Header from "../Header/Header.jsx";
import Navigation from "../Navigation/Navigation.jsx";
import Tags from "../Tags/Tags.jsx";
import ButtonList from "../ButtonList/ButtonList.jsx";
import Footer from "../Footer/Footer.jsx";
import LoadData from "../LoadData/LoadData.jsx";
import TelegramLink from "../TelegramLink/TelegramLink.jsx";
import EmailVerifier from "../EmailVerifier/EmailVerifier.jsx";
import Preloader from "../Preloader/Preloader.jsx";

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(() => {
    const storedCategory = localStorage.getItem("selectedCategory");

    // Проверяем, что значение это строка (и не null)
    if (typeof storedCategory === "string" && storedCategory.trim() !== "") {
      return storedCategory; // Возвращаем строку, если всё корректно
    }

    return "ae"; // Значение по умолчанию
  });

  const [selectedTag, setSelectedTag] = useVisible(null);
  const [tags, setTags] = useState([]);

  const [buttons, setButtons] = useState([]);
  const [isButtonsUpdated, setIsButtonsUpdated] = useState(false);
  const [findButton, setFindButton] = useState("");

  const [isLoader, setIsLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [lastHash, setLastHash] = useState(() => {
    const savedHash = localStorage.getItem("lastHash");
    return savedHash || "";
  });

  const [isInfoTooltip, setIsInfoTooltip] = useState({
    isOpen: false,
    status: true,
    text: "",
  });

  const [load, setLoad] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isTelegramLinked, setIsTelegramLinked] = useState(
    JSON.parse(localStorage.getItem("isTelegramLinked")) || false,
  );
  const [emailVerifier, setEmailVerifier] = useState(
    JSON.parse(localStorage.getItem("emailVerifier")) || false,
  );
  const [device, setDevice] = useState(false);
  const [serverError, setServerError] = useState(null);

  function closeInfoTooltip() {
    setIsInfoTooltip({ ...isInfoTooltip, isOpen: false });
  }

  function handleRegister({ name, family, login, email, password, telegram }) {
    setIsLoader(true);
    mainApi
      .createUser({
        name,
        family,
        login,
        email,
        password,
        telegram: { username: telegram }, // Соблюдаем требуемый формат
      })
      .then((response) => {
        if (response.data.email === email) {
          handleLogin({ email, password });
        }
      })
      .catch((err) => setServerError(err.message))
      .finally(() => setIsLoader(false));
  }

  function handleLogin({ email, password }) {
    setIsLoader(true);
    mainApi
      .login({ email, password })
      .then((jwt) => {
        if (jwt.token) {
          localStorage.setItem("jwt", jwt.token);
          return mainApi.getUserInfo();
        }
        // Если нет jwt.token, возможно, следует выбросить ошибку или обработать этот случай иначе.
        throw new Error("JWT token is missing.");
      })
      .then((data) => {
        if (data) {
          setLoggedIn(true);
          setCurrentUser(data);
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        setServerError(err.message);
        // Если возникла ошибка при входе или получении данных пользователя, может быть полезно очистить jwt из localStorage
        localStorage.removeItem("jwt");
      })
      .finally(() => {
        setIsLoader(false);
      });
  }

  function handleUpdateUser(id, data) {
    mainApi
      .updateUser(id, data)
      .then((response) => {
        console.log("Update user info:", id);
      })
      .catch((error) => {
        console.error("Failed to update user info:", currentUser._id, error);
        setIsInfoTooltip({
          isOpen: true,
          status: false,
          text: "Failed to update user info: " + error,
        });
      });
  }

  function handleSignOut() {
    mainApi
      .updateUser(currentUser._id, {
        license: {
          status: false,
          hostName: "",
          userName: "",
        },
      })
      .then((response) => {
        console.log("Subscription updated for user:", currentUser._id);
        // Здесь может быть код для обновления контекста, если это необходимо
      })
      .catch((error) => {
        console.error(
          "Failed to update subscription for user:",
          currentUser._id,
          error,
        );
      });
    setCurrentUser({});
    setDevice(false);
    setLoggedIn(false);
    localStorage.removeItem("jwt");
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      setDataLoaded(true);
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      setIsLoader(true);
      mainApi
        .getUserInfo()
        .then((data) => {
          if (data) {
            setLoggedIn(true);
            setCurrentUser(data);
            navigate(path, { replace: true });

            if (!data.license || data.license.status) {
              window.jsx.evalFile("./Harchenko/AE2 Extension/ae2data.jsx");
              window.jsx.evalScript(`run(userInfo());`, function (result) {
                if (result && result !== "undefined") {
                  const userInfo = JSON.parse(result);
                  if (!device) {
                    setDevice({
                      hostName: userInfo.hostName,
                      userName: userInfo.userName,
                    });
                  }
                  var deviceUpdate = {
                    license: {
                      status: true,
                      hostName: userInfo.hostName,
                      userName: userInfo.userName,
                    },
                  };
                  if (userInfo.hostName === data.license.hostName) {
                    mainApi
                      .updateUser(data._id, deviceUpdate)
                      .then((data) => {
                        setCurrentUser(data);
                        console.log(
                          "Subscription updated for user:",
                          currentUser._id,
                        );
                        // Здесь может быть код для обновления контекста, если это необходимо
                      })
                      .catch((error) => {
                        console.error(
                          "Failed to update subscription for user:",
                          currentUser._id,
                          error,
                        );
                      });
                  }
                }
              });
            }
          }
        })
        .catch((err) =>
          setIsInfoTooltip({ isOpen: true, status: false, text: err }),
        )
        .finally(() => {
          setIsLoader(false);
          setLoad(true);
          setLoading(false);
        });
    } else {
      setLoad(true);
    }
  }, []);

  useEffect(() => {
    const fetchButtons = () => {
      setIsLoader(true);
      mainApi
        .getButtons()
        .then((fetchedButtons) => {
          // Сортировка полученных кнопок по имени
          fetchedButtons = fetchedButtons.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setButtons(fetchedButtons);
          setIsButtonsUpdated(false); // Reset the flag after successfully fetching the buttons
        })
        .catch((err) => {
          setIsInfoTooltip({ isOpen: true, status: false, text: err });
        })
        .finally(() => {
          setIsLoader(false);
        });
    };

    if (loggedIn) {
      fetchButtons();
    }
  }, [loggedIn, isButtonsUpdated]);

  useEffect(() => {
    if (currentUser != null) {
      const { emailVerifier, telegram, tags } = currentUser;

      if (emailVerifier) {
        const emailVerify = emailVerifier.verify;
        setEmailVerifier(emailVerify); // Обновляем состояние
        localStorage.setItem("emailVerifier", JSON.stringify(emailVerify)); // Сохраняем в localStorage
      }

      // Проверка наличия currentUser и его свойства telegram
      if (telegram) {
        const isLinked = telegram.isLinked;
        setIsTelegramLinked(isLinked); // Обновляем состояние
        localStorage.setItem("isTelegramLinked", JSON.stringify(isLinked)); // Сохраняем в localStorage
      }

      if (tags) {
        const savedTags = localStorage.getItem("tags");
        if (savedTags && JSON.parse(savedTags).length > 0) {
          setTags(JSON.parse(savedTags));
        } else {
          localStorage.setItem("tags", JSON.stringify(tags.aeTags));
          setTags(tags.aeTags);
        }
      }
    }
  }, [currentUser]);

  const routing = useRoutes([
    {
      path: "signup",
      element: loggedIn ? (
        <Navigate to="/" replace />
      ) : (
        <Register handleRegister={handleRegister} serverError={serverError} />
      ),
    },
    {
      path: "signin",
      element: loggedIn ? (
        <Navigate to="/" replace />
      ) : (
        <Login handleLogin={handleLogin} serverError={serverError} />
      ),
    },
    {
      path: "/",
      element: loggedIn ? (
        emailVerifier ? (
          isTelegramLinked ? (
            currentUser.subscription.status ? (
              dataLoaded ? (
                currentUser.license.hostName === device.hostName ||
                currentUser.role === "admin" ? (
                  <>
                    <Header
                      handleUpdateUser={handleUpdateUser}
                      handleSignOut={handleSignOut}
                      lastHash={lastHash}
                      findButton={findButton}
                      setFindButton={setFindButton}
                    />
                    <Navigation
                      selectedCategory={selectedCategory}
                      onSelectCategory={setSelectedCategory}
                    />
                    <Tags
                      currentTag={selectedTag}
                      onTagChange={setSelectedTag}
                      tags={tags}
                      setTags={setTags}
                    />
                    <ButtonList
                      buttons={buttons}
                      search={findButton}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      selectedTag={selectedTag}
                      tags={tags}
                    />
                    <Footer />
                  </>
                ) : (
                  <DeviceCheck
                    device={device}
                    setCurrentUser={setCurrentUser}
                    setDevice={setDevice}
                  />
                )
              ) : (
                process.env.NODE_ENV === "production" && (
                  <LoadData
                    isOpen={!dataLoaded}
                    onDataLoaded={() => setDataLoaded(true)}
                    lastHash={lastHash}
                    setLastHash={setLastHash}
                  />
                )
              )
            ) : (
              <SubscriptionCheck handleSignOut={handleSignOut} />
            )
          ) : (
            // Условие для привязки Telegram
            <TelegramLink
              setIsTelegramLinked={setIsTelegramLinked}
              handleUpdateUser={handleUpdateUser}
              handleSignOut={handleSignOut}
            />
          )
        ) : (
          <EmailVerifier
            setEmailVerifier={setEmailVerifier}
            handleUpdateUser={handleUpdateUser}
            handleSignOut={handleSignOut}
          />
        )
      ) : (
        <Navigate to="/signin" />
      ),
    },
  ]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div>
        <InfoTooltip onClose={closeInfoTooltip} isInfoTooltip={isInfoTooltip} />
        {isLoader ? <Preloader isOpen={!dataLoaded} /> : routing}
      </div>
    </CurrentUserContext.Provider>
  );
}
