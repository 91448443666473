import React, { useState, useEffect, useContext } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import "./ButtonList.css";
import ButtonCard from "../ButtonCard/ButtonCard.jsx";
import EaseKey from "../EaseKey/EaseKey.jsx";
import ParagraphButtons from "../ParagraphButtons/ParagraphButtons.jsx";
import AnchorAlignNull from "../AnchorAlignNull/AnchorAlignNull.jsx";
import ChangeCompositionSettings from "../ChangeCompositionSettings/ChangeCompositionSettings.jsx";
import useVisible from "../../hooks/useVisible.jsx";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const ButtonList = ({
  buttons,
  search,
  selectedCategory,
  selectedTag,
  tags,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const [filteredButtons, setFilteredButtons] = useState([]);
  const [activeSlide, setActiveSlide] = useVisible("activeSlide");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const additionalNavigation = [
    {
      component: <EaseKey />,
      icon: <i class="fa-regular fa-rhombus"></i>,
      helpTip: "Keyframe Easing",
    },
    {
      component: <AnchorAlignNull script={"$.ae.moveAnchor"} />,
      icon: <i class="fa-solid fa-anchor"></i>,
      helpTip: "Anchor Point",
    },
    {
      component: <AnchorAlignNull script={"alignTo"} />,
      icon: <i class="fa-sharp fa-solid fa-objects-align-center-vertical"></i>,
      helpTip: "Align Layers",
    },
    {
      component: <AnchorAlignNull script={"newNull"} />,
      icon: <i class="fa-sharp fa-solid fa-layer-plus"></i>,
      helpTip: "Add Null",
    },
    {
      component: <ChangeCompositionSettings />,
      icon: <i class="fa-regular fa-gear"></i>,
      helpTip: "Comp Setting",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let filtered;
    if (search) {
      filtered = buttons.filter(
        (button) =>
          button.name.toLowerCase().includes(search.toLowerCase()) &&
          currentUser.subscription[button.category],
      );
    } else {
      const localStorageKey = `buttonOrder-${selectedCategory}`;
      const savedButtonIdsOrder =
        JSON.parse(localStorage.getItem(localStorageKey)) || [];

      const currentButtonsInCategory = buttons.filter(
        (button) => button.category === selectedCategory,
      );

      // Сначала упорядочиваем кнопки на основе сохраненного порядка
      const orderedExistingButtons = savedButtonIdsOrder
        .map((savedId) =>
          currentButtonsInCategory.find((btn) => btn._id === savedId),
        )
        .filter(Boolean); // Фильтр Boolean убирает возможные null значения

      // Затем добавляем кнопки, которые еще не были сохранены, в конец списка
      filtered = [
        ...orderedExistingButtons,
        ...currentButtonsInCategory.filter(
          (btn) =>
            !orderedExistingButtons.some(
              (savedBtn) => savedBtn._id === btn._id,
            ),
        ),
      ];
    }

    setFilteredButtons(filtered);
  }, [selectedCategory, buttons, search]);

  const moveButton = (_id, atIndex) => {
    const { button, index } = findButton(_id);
    const updatedButtons = update(filteredButtons, {
      $splice: [
        [index, 1],
        [atIndex, 0, button],
      ],
    });

    setFilteredButtons(updatedButtons);

    const localStorageKey = `buttonOrder-${selectedCategory}`;
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(updatedButtons.map((button) => button._id)),
    ); // Сохраняем только идентификаторы
  };

  const findButton = (_id) => {
    const button = filteredButtons.find((b) => b._id === _id);
    return {
      button,
      index: filteredButtons.indexOf(button),
    };
  };

  const toggleNavigation = (indx) => {
    if (activeSlide === indx) {
      setActiveSlide(false);
    } else {
      setActiveSlide(indx);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <section className="buttons-card">
        <div className="additional__navigation">
          {additionalNavigation.map((obj, indx) => (
            <div
              className={`additional__navigation_item ${activeSlide === indx ? "additional__navigation_item--active" : ""}`}
              onClick={() => toggleNavigation(indx)}
              title={obj.helpTip}
            >
              {obj.icon}
            </div>
          ))}
        </div>
        {additionalNavigation[activeSlide] ? (
          <div className="sliderContainer">
            <div className="slider">
              {additionalNavigation[activeSlide].component}
            </div>
          </div>
        ) : null}

        <ParagraphButtons />

        {filteredButtons.length ? (
          <ul className="buttons-card__list">
            {filteredButtons.map((data) => (
              <ButtonCard
                key={data._id}
                button={data}
                moveButton={moveButton}
                findButton={findButton}
                selectedTag={selectedTag}
                tags={tags}
              />
            ))}
          </ul>
        ) : null}
      </section>
    </DndProvider>
  );
};

export default ButtonList;
