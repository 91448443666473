import React, { useState } from "react";
import "./ChangeCompositionSettings.css";
import useVisible from "../../hooks/useVisible.jsx";
import handleClick from "../../utils/handleClick.js";
import BackgroundColor from "../BackgroundColor/BackgroundColor.jsx";

const ChangeCompositionSettings = () => {
  // Состояния для полей
  const [alignment, setAlignment] = useVisible("change-comp-Alignment");
  const [activeWidth, setActiveWidth] = useVisible("change-comp-Active-Width");
  const [activeHeight, setActiveHeight] = useVisible(
    "change-comp-Active-Height",
  );
  const [activeFrameRate, setActiveFrameRate] = useVisible(
    "change-comp-Active-FrameRate",
  );
  const [width, setWidth] = useVisible("change-comp-Width");
  const [height, setHeight] = useVisible("change-comp-Height");
  const [frameRate, setFrameRate] = useVisible("change-comp-FrameRate");
  const [duration, setDuration] = useVisible("change-comp-Duration");
  const [updateNested, setUpdateNested] = useVisible(
    "change-comp-Update-Nested",
  );

  const alignmentTypes = [
    { arg: "top-left", name: "Top Left" },
    { arg: "top", name: "Top" },
    { arg: "top-right", name: "Top Right" },
    { arg: "left", name: "Left" },
    { arg: "center", name: "Center" },
    { arg: "right", name: "Right" },
    { arg: "bottom-left", name: "Bottom Left" },
    { arg: "bottom", name: "Bottom" },
    { arg: "bottom-right", name: "Bottom Right" },
  ];

  const options = [
    {
      checkbox: "Width",
      input: true,
      state: activeWidth,
      setState: setActiveWidth,
      value: width,
      setValue: setWidth,
    },
    {
      checkbox: "Height",
      input: true,
      state: activeHeight,
      setState: setActiveHeight,
      value: height,
      setValue: setHeight,
    },
    {
      checkbox: "Frame Rate",
      input: true,
      state: activeFrameRate,
      setState: setActiveFrameRate,
      value: frameRate,
      setValue: setFrameRate,
    },
    {
      checkbox: "Update Nested",
      input: false,
      state: updateNested,
      setState: setUpdateNested,
    },
  ];

  return (
    <div className="composition-settings__container">
      <BackgroundColor />
      <div className="composition-settings__dropdown">
        <select
          value={alignment}
          onChange={(e) => setAlignment(e.target.value)}
        >
          {alignmentTypes.map((type) => (
            <option key={type.arg} value={type.arg}>
              {type.name}
            </option>
          ))}
        </select>
      </div>

      {options.map((option, index) => (
        <div key={index} className="composition-settings__option-container">
          <div className="render-group__checkbox composition-settings__option-checkbox">
            <input
              id={option.checkbox + " change-comp-setting"}
              type="checkbox"
              checked={option.state}
              onChange={() => option.setState(!option.state)}
              className="hidden-checkbox"
            />
            <label htmlFor={option.checkbox + " change-comp-setting"}>
              <span className="custom-checkbox"></span>
              <span className="label-text">{option.checkbox}</span>
            </label>
          </div>
          {option.input && (
            <input
              type="number"
              value={option.value || ""}
              onChange={(e) => option.setValue(e.target.value)}
              disabled={!option.state}
              className={`rename__input ${!option.state ? "disabled" : ""}`}
            />
          )}
        </div>
      ))}
      <div
        className={`resize-group__button composition-settings__button ${!activeWidth && !activeHeight && !activeFrameRate ? "disabled" : ""}`}
        onClick={() => {
          handleClick("changeCompositionSettings", {
            alignment: alignment,
            width: activeWidth ? Number(width) : false,
            height: activeHeight ? Number(height) : false,
            frameRate: activeFrameRate ? Number(frameRate) : false,
            updateNested: updateNested,
          });
        }}
      >
        Apply
      </div>
    </div>
  );
};

export default ChangeCompositionSettings;
